import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Button from "wes_shell_app/button";
import { Dialog } from "wes_shell_app/dialog";
import RackShelfDialogForm from "./rack-shelf-dialog-form";
import { useShelvesStore } from "features/rack/hooks/use-shelves-store";
import { observer } from "mobx-react";

const RackShelvClone = observer(() => {
  const store = useShelvesStore();
  const [isOverrideDialogVisible, setOverrideDialogVisibility] =
    useState(false);

  const handleShelvConfirm = async () => {
    store.addNewShelf();
    store.resetDialogShelf();
    store.resetSelectedCloneShelf();
    store.resetShelvTableRowSelected();
    setOverrideDialogVisibility(false);
  };

  const handleShelvCancel = () => {
    store.resetDialogShelf();
    setOverrideDialogVisibility(false);
  };

  useEffect(() => {
    isOverrideDialogVisible && store.setDialogShelf(store.selectedCloneShelf);
  }, [isOverrideDialogVisible, store, store.selectedCloneShelf]);

  return (
    <Box>
      <Dialog
        open={isOverrideDialogVisible}
        cancelAction={handleShelvCancel}
        cancelLabel="cancel"
        confirmAction={handleShelvConfirm}
        confirmLabel="save"
        confirmActionVariant="confirm"
        intlTitle={"clone"}
      >
        <RackShelfDialogForm editLevel={true} />
      </Dialog>
      {store.selectedCloneShelf && (
        <Button
          variant={"contained"}
          intlId={"clone"}
          onClick={() => setOverrideDialogVisibility(true)}
        />
      )}
    </Box>
  );
});

export default RackShelvClone;
