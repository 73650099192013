import { environment } from "environment";
import { IParametersModels } from "wes_shell_app/parameters-view-store";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { IParametersFilterParams } from "wes_shell_app/parameters-view-store";

export class VoiceParametersDataTableProvider extends DataTableStore<
  IParametersModels,
  IParametersFilterParams,
  string
> {
  readonly sharedContextStore = new SharedContextStore();
  get endpointUrl(): string {
    return `ui/${this.stationId}/parameter`;
  }

  protected serviceApiUrl = environment.voiceServiceApi;
  get stationId() {
    return this.sharedContextStore.appContext.currentStationId;
  }

  get defaultTimeFromValue() {
    let timeFrom = new Date();
    timeFrom.setDate(timeFrom.getDate() - 1);
    return timeFrom;
  }

  get defaultFilter() {
    return new Map<IParametersFilterParams, any>([]);
  }

  get filterDependencies(): Map<
    IParametersFilterParams,
    IParametersFilterParams[]
  > {
    return new Map<IParametersFilterParams, IParametersFilterParams[]>();
  }
}
