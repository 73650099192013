import { MobXProviderContext } from "mobx-react";
import * as React from "react";
import { VoiceRootStore } from "stores/voice-root-store";

type IAppMobxContext = {
  rootStore: VoiceRootStore;
};

export function useVoiceAppMobxContext() {
  return React.useContext<IAppMobxContext>(MobXProviderContext as any);
}
