import SingleDataStoreProvider from "wes_shell_app/single-data-store-provider";
import { IAPIPrinterModel } from "models/server-models";
import { environment } from "environment";

export class PrintSingleDataProvider extends SingleDataStoreProvider<IAPIPrinterModel> {
  get endpointUrl(): string {
    return "ui/printer";
  }
  protected serviceApiUrl = environment.voiceServiceApi;
}
