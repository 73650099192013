import { Box } from "@mui/material";
import { useShelvesStore } from "../hooks/use-shelves-store";

const RackIamge = () => {
  const store = useShelvesStore();

  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column-reverse"
      width="80%"
      maxWidth={"30rem"}
      borderLeft={8}
      borderRight={8}
      borderColor="primary.main"
    >
      {store.shelves
        .slice()
        .sort((a, b) => a.level - b.level)
        .map((shelf, index) => (
          <Box
            sx={{
              height: `${shelf.height / 200}rem`,
            }}
            key={shelf.name}
            display="flex"
            gap={1}
            mb={index === 0 ? 0 : 1}
            borderBottom={index === 0 ? 0 : 8}
            borderColor="primary.main"
            px={1}
          >
            {!store.rack?.zeroGround && index === 0
              ? null
              : Array.from({ length: shelf.positions }).map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      height: "100%",
                      flexGrow: 1,
                      backgroundColor: "#bcaaa4",
                      borderColor: "#926657",
                    }}
                    component="div"
                    border={1}
                  ></Box>
                ))}
          </Box>
        ))}
    </Box>
  );
};

export default RackIamge;
