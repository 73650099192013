import { Dialog } from "wes_shell_app/dialog";
import RackEditDialogForm from "./rack-edit-dialog-form";
import { useRackStore } from "features/rack/hooks/use-rack-store";

type IRackEditDialogProps = {
  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;
};

const RackEditDialog = (props: IRackEditDialogProps) => {
  const store = useRackStore();
  const { openDialog, setOpenDialog } = props;

  const handleEditRack = async () => {
    await store.editRackAPI();
    setOpenDialog(false);
  };

  const handleEditRackCancel = () => {
    store.resetSelectEditRack();
    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      cancelAction={handleEditRackCancel}
      cancelLabel="cancel"
      confirmAction={handleEditRack}
      confirmLabel="save"
      confirmActionVariant="confirm"
      intlTitle={"rackEditTitle"}
    >
      <RackEditDialogForm />
    </Dialog>
  );
};

export default RackEditDialog;
