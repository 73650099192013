import { SelectItemType } from "wes_shell_app/types";

export type IRackFilterParams = 'name' | 'active' | 'mobility' | 'type';

export const StateItems: SelectItemType[] = [
    {
        contentIntl: "statusActive",
        id: "true",
    },
    {
        contentIntl: "statusInactiv",
        id: "false",
    },
];

export const ShelfTypeItems: SelectItemType[] = [
    {
        contentIntl: "rackTypeSingle",
        id: "single",
    },
    {
        contentIntl: "rackTypeDouble",
        id: "double",
    },
];

export const MobilityItems: SelectItemType[] = [
    {
        contentIntl: "yes",
        id: "true",
    },
    {
        contentIntl: "no",
        id: "false",
    },
];

export const ZeroGroundItems: SelectItemType[] = [
    {
        contentIntl: "yes",
        id: "true",
    },
    {
        contentIntl: "no",
        id: "false",
    },
];

export const NumericRackingItems: SelectItemType[] = [
    {
        contentIntl: "yes",
        id: "true",
    },
    {
        contentIntl: "no",
        id: "false",
    },
];

export const NumericShelfItems: SelectItemType[] = [
    {
        contentIntl: "yes",
        id: "true",
    },
    {
        contentIntl: "no",
        id: "false",
    },
];