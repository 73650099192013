import { makeAutoObservable } from "mobx";

export class RackShelvesNameStore {
    constructor() {
        makeAutoObservable(this);
    }

    private _isNumeric(value) {
        return /^\d+$/.test(value);
    }

    private _incrementString(str: string): string {
        let result = '';
        let carry = true;

        for (let i = str.length - 1; i >= 0; i--) {
            let charCode = str.charCodeAt(i);

            if (carry) {
                if (charCode === 90) { // 'Z'
                    result = 'A' + result;
                    carry = true;
                } else {
                    result = String.fromCharCode(charCode + 1) + result;
                    carry = false;
                }
            } else {
                result = str[i] + result;
            }
        }

        if (carry) {
            result = 'A' + result;
        }

        return result;
    }

    getNextPosition(lastPositon: string) {
        if (this._isNumeric(lastPositon)) {
            return lastPositon = String(Number(lastPositon) + 1);
        } else {
            return lastPositon = this._incrementString(lastPositon.toUpperCase());
        }
    }
}