import { observer } from "mobx-react";
import React from "react";
import BaseView from "wes_shell_app/base-view";
import { ParametersTable } from "wes_shell_app/parameters-table";
import { useVoiceParametersStore } from "../hooks/use-parameters-store";

const ParametersView = observer(() => {
  const store = useVoiceParametersStore();
  return (
    <BaseView>
      <ParametersTable store={store} />
    </BaseView>
  );
});

export default ParametersView;
