import { environment } from "environment";
import { IAPIPrinterModel } from "models/server-models";
import DataTableStore from "wes_shell_app/data-table-store";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { deleteItemWithParams } from "wes_shell_app/api-utils";
import { IAPIPrinterClientModel } from "models/client-models";

export type IVoicePrintersFilterParams = "type" | "name" | "stationId";

export class VoicePrintersDataTableProvider extends DataTableStore<
  IAPIPrinterModel,
  IVoicePrintersFilterParams
> {
  readonly sharedContextStore = new SharedContextStore();
  get endpointUrl(): string {
    return `ui/printer`;
  }

  protected serviceApiUrl = environment.voiceServiceApi;
  get stationId() {
    return this.sharedContextStore
      ? this.sharedContextStore.appContext.currentStationId
      : 4; //TODO amorys prepsat dynamicke načitani current stationID
  }

  get defaultTimeFromValue() {
    let timeFrom = new Date();
    timeFrom.setDate(timeFrom.getDate() - 1);
    return timeFrom;
  }

  get defaultFilter() {
    return new Map<IVoicePrintersFilterParams, any>([
      ["stationId", this.stationId],
    ]);
  }

  get filterDependencies(): Map<
    IVoicePrintersFilterParams,
    IVoicePrintersFilterParams[]
  > {
    return new Map<IVoicePrintersFilterParams, IVoicePrintersFilterParams[]>();
  }
  async deletePrinter(printer: IAPIPrinterClientModel) {
    try {
      await deleteItemWithParams(
        environment.voiceServiceApi + "ui/printer",
        printer.id,
        { stationId: printer.stationId }
      );

      this.forceReload();
    } catch (error) {
      console.error("Failed to delete printer", error);
    }
  }
}
