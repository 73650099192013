import { Dialog } from "wes_shell_app/dialog";
import { PrinterEditForm } from "./printer-edit-dialog-form";
import { getTranslation } from "wes_shell_app/localization-utils";

type IPrinterEditDialogFormType = {
  showDialog: boolean;
  onClose: () => void;
  confirmAction: () => void;
};

export const PrinterEditDialogForm = (props: IPrinterEditDialogFormType) => {
  const { showDialog, onClose, confirmAction } = props;

  return (
    <Dialog
      open={showDialog}
      intlTitle="editPrinter"
      cancelAction={onClose}
      confirmAction={confirmAction}
    >
      <PrinterEditForm />
    </Dialog>
  );
};
