import { observer } from "mobx-react";
import React from "react";
import BaseView from "wes_shell_app/base-view";
import { PrintersDataTable } from "../components/printers-data-table";
import { PrintersDataTableFilter } from "../components/printers-data-table-filter";

const PrintersView = observer(() => {
  return (
    <BaseView>
      <PrintersDataTableFilter />
      <PrintersDataTable />
    </BaseView>
  );
});

export default PrintersView;
