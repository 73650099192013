import { useShelvesStore } from "features/rack/hooks/use-shelves-store";
import { Dialog } from "wes_shell_app/dialog";
import RackShelfDialogForm from "./rack-shelf-dialog-form";

type IRackShelfEditDialogProps = {
  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;
};

const RackShelfEdit = (props: IRackShelfEditDialogProps) => {
  const store = useShelvesStore();
  const { openDialog, setOpenDialog } = props;

  const handleEditShelv = async () => {
    store.editShelf();
    store.resetDialogShelf();
    setOpenDialog(false);
  };

  const handleEditShelvCancel = () => {
    store.resetDialogShelf();
    setOpenDialog(false);
  };

  return (
    <Dialog
      open={openDialog}
      cancelAction={handleEditShelvCancel}
      cancelLabel="cancel"
      confirmAction={handleEditShelv}
      confirmLabel="save"
      confirmActionVariant="confirm"
      intlTitle={"rackEditTitle"}
    >
      <RackShelfDialogForm editLevel={false} />
    </Dialog>
  );
};

export default RackShelfEdit;
