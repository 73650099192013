import { IAPIPrinterClientModel } from "models/client-models";
import {
  IAPIPrinterModel,
  IAPIPrinterModelsProtocols,
} from "models/server-models";
import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import {
  VoicePrintersDataTableProvider,
  IVoicePrintersFilterParams,
} from "../data/voice-printers-data-table-provider";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { action, makeAutoObservable, makeObservable, observable } from "mobx";
import { dataGET, updateItem } from "wes_shell_app/api-utils";
import { environment } from "environment";
import alerts from "wes_shell_app/alerts";
import { getTranslation } from "wes_shell_app/localization-utils";

export class VoicePrintersViewStore extends BaseTableViewStore<
  IAPIPrinterModel,
  IAPIPrinterClientModel,
  IVoicePrintersFilterParams,
  VoicePrintersDataTableProvider
> {
  constructor() {
    super(new VoicePrintersDataTableProvider());
    makeObservable(this, {
      selectedPrinter: observable,
      setSelectedPrinter: action,
      printerModels: observable,
      setPrinterModels: action,
      printerProtocols: observable,
      setPrinterProtocols: action,
    });
  }
  selectedPrinter: IAPIPrinterClientModel | null = null;
  printerModels: string[] | null = null;
  printerProtocols: string[] | null = null;

  setPrinterModels = (models: string[]) => {
    this.printerModels = models;
  };

  setPrinterProtocols = (protocol: string[]) => {
    this.printerProtocols = protocol;
  };

  setSelectedPrinter(selectedPrinter: IAPIPrinterClientModel) {
    this.selectedPrinter = selectedPrinter;
  }

  setEditData(value: Partial<IAPIPrinterClientModel>) {
    this.selectedPrinter = { ...this.selectedPrinter, ...value };
  }

  validationErrors = {
    printerCode: false,
    name: false,
    model: false,
    type: false,
    ipAdress: false,
    btName: false,
  };

  async updateDataAPI(data: IAPIPrinterClientModel) {
    try {
      await updateItem(`${environment.voiceServiceApi}ui/printer/${data.id}`, {
        ...data,
      });
      alerts.success({ message: getTranslation("printerChange") });
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
    }
  }

  async getModelAPI() {
    try {
      const response: IAPIPrinterModelsProtocols = await dataGET(
        `${environment.voiceServiceApi}ui/printerParam?stationId=${this.stationId}`
      );
      this.setPrinterModels(response.model);
      this.setPrinterProtocols(response.protocol);
    } catch (e) {
      const fixedErrorMessage = (e.message as string).replace("Error: ", "");
      const errorModel = JSON.parse(fixedErrorMessage);
      alerts.error({ message: errorModel.message });
    }
  }

  private readonly appContext = new SharedContextStore();

  get currentUserStore() {
    return this.appContext.appContext.accessRights;
  }
  get isLoaded() {
    return this.currentUserStore.isLoaded && this.tableProvider.isLoaded;
  }
  get defaultTimeFromValue() {
    return this.tableProvider.defaultTimeFromValue;
  }
  get isAdmin() {
    return this.currentUserStore.isAdminRole || false;
  }
  get stationId() {
    return this.currentUserStore.currentStationId;
  }

  deletePrinter = this.tableProvider.deletePrinter;
}
