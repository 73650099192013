import React from "react";
import { Routes, Route } from "react-router-dom";
import routerStore from "wes_shell_app/router-store";
import DashboardView from "features/dashboard/views/dashboard-view";
import PrintersView from "features/printers/views/printers-view";
import ParametersView from "features/parameters/views/parameters-view";
import { useNavigationStore } from "wes_shell_app/use-navigation-store";
import { observer } from "mobx-react";
import { voiceMenu, VoiceRoutePaths } from "./voice-menu";
import RackView from "features/rack/views/rack-view";

export const VoiceRoutes = observer(() => {
  const navStore = useNavigationStore();

  React.useEffect(() => {
    routerStore.addPaths([...Object.values(VoiceRoutePaths)]);
    navStore.setItems(voiceMenu().items);
  }, []);

  return (
    <Routes>
      <Route path={VoiceRoutePaths.home} element={<DashboardView />} />
      <Route path={VoiceRoutePaths.printers} element={<PrintersView />} />
      <Route path={VoiceRoutePaths.parameters} element={<ParametersView />} />
      <Route path={VoiceRoutePaths.racks} element={<RackView />} />
    </Routes>
  );
});
