import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { useShelvesStore } from "../hooks/use-shelves-store";
import { observer } from "mobx-react";
import { RackShelvesStore } from "../stores/rack-shelves-store";
import { IServerCreateRackShelfModel } from "models/server-models";
import { Box, TableCell, TableRow } from "@mui/material";
import { ArrowDropDown, ArrowDropUp, Delete, Edit } from "@mui/icons-material";
import Button from "wes_shell_app/button";
import { useState } from "react";
import RackShelfEdit from "./dialogs/shelves/rack-shelf-edit";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "name",
  },
  {
    label: "shelvLevel",
  },
  {
    label: "shelvHeight",
  },
  {
    label: "shelvNumberPosition",
  },
];

const RackShelvesTable = observer(() => {
  const store = useShelvesStore();
  const [dialogCloneShelv, setDialogCloneShelv] = useState<boolean>(false);

  const handleRowSelect = (id: number) => {
    if (store.shelvTableRowSelected === id) {
      store.resetShelvTableRowSelected();
      store.setShelvPlanCloneButton(false);
      store.resetSelectedCloneShelf();
    } else {
      store.setShelvTableRowSelected(id);
      store.setShelvPlanCloneButton(true);
      store.setSelectedCloneShelf(id);
    }
  };

  return (
    <>
      <RackShelfEdit
        setOpenDialog={setDialogCloneShelv}
        openDialog={dialogCloneShelv}
      />
      <DataTable
        isLoading={!store.isLoaded}
        rows={store.shelves}
        columns={columns}
        onPageChange={() => {}}
        rowTemplate={(item) => (
          <RowTemplate
            key={item.shelfId}
            item={item}
            store={store}
            isAdmin={store.isAdmin}
            onSelect={() => handleRowSelect(item.shelfId)}
            setDialogCloneShelv={setDialogCloneShelv}
          />
        )}
      />
    </>
  );
});

type IRowTemplateProps = {
  item: IServerCreateRackShelfModel;
  store: RackShelvesStore;
  isAdmin?: boolean;
  onSelect: () => void;
  setDialogCloneShelv: (value: boolean) => void;
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const { item, store, onSelect, setDialogCloneShelv } = props;
  const { shelfId, name, level, positions, height } = item;

  return (
    <TableRow
      onClick={onSelect}
      selected={store.shelvTableRowSelected === shelfId}
      sx={{
        cursor: "pointer",
        backgroundColor:
          store.shelvTableRowSelected === shelfId
            ? "rgb(37 58 127 / 13%) !important"
            : "",
      }}
    >
      <TableCell>{name}</TableCell>
      <TableCell>{level}</TableCell>
      <TableCell>{height}</TableCell>
      <TableCell>
        {!store.rack.zeroGround && level === 1 ? "" : positions}
      </TableCell>
      <TableCell>
        <Box className="flex gap-4 justify-end pr-2">
          {store.rightEditShelv && (
            <Button
              variant="icon"
              icon={<Edit color="primary" />}
              onClick={(event) => {
                event.stopPropagation();
                store.setDialogShelf(item);
                setDialogCloneShelv(true);
              }}
            />
          )}
          {store.rightRemoveShelv && (
            <Button
              variant="icon"
              icon={<Delete color="error" />}
              onClick={(event) => {
                event.stopPropagation();
                store.deleteShelf(shelfId);
              }}
            />
          )}
          {store.rightMoveShelv && (
            <Box display="flex" flexDirection="column">
              <Button
                className="p-1 w-5 max-h-5"
                variant="icon"
                icon={<ArrowDropUp color="primary" />}
                onClick={(event) => {
                  event.stopPropagation();
                  store.shelvLevelUp(level);
                }}
              />
              <Button
                className="p-1 w-5 max-h-5"
                variant="icon"
                icon={<ArrowDropDown color="primary" />}
                onClick={(event) => {
                  event.stopPropagation();
                  store.shelvLevelDown(level);
                }}
              />
            </Box>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
});

export default RackShelvesTable;
