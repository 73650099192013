import { useRackStore } from "features/rack/hooks/use-rack-store";
import { RackRequestDataProvider } from "features/rack/stores/data/rack-request-data-provider";
import { IClientRackModel } from "models/client-models";
import { useState } from "react";
import ModelDialog, { IWithModalDialog } from "wes_shell_app/model-dialog";
import { RackNewRackDialog } from "./rack-new-rack-dialog";

type IRackModelDialogFormPropss = IWithModalDialog<IClientRackModel>;

const RackModelDialogForm = (props: IRackModelDialogFormPropss) => {
  const [_patchedItem, setPatchedItem] =
    useState<IRackModelDialogFormPropss | null>(null);

  const store = useRackStore();
  const provider = new RackRequestDataProvider();

  return (
    <>
      <ModelDialog
        intlId="rackNew"
        onSuccess={store.forceReload}
        dialogTitleIntl={props.dialogTitleIntl}
        type={props.type}
        provider={provider}
        onCancel={() => store.resetRackUuid()}
      >
        <RackNewRackDialog />
      </ModelDialog>
    </>
  );
};

export default RackModelDialogForm;
