import NumericInputField from "wes_shell_app/numeric-input-field";
import { useShelvesStore } from "features/rack/hooks/use-shelves-store";
import { Box } from "@mui/material";
import { getTranslation } from "wes_shell_app/localization-utils";

const RackShelfDialogForm = ({ editLevel }: { editLevel: boolean }) => {
  const store = useShelvesStore();

  return (
    <Box className={"flex flex-col gap-4"}>
      <Box className={"flex gap-4"}>
        <NumericInputField
          required
          value={store.dialogShelf?.height}
          label={`${getTranslation("shelvHeight")} (mm)`}
          onChange={(height) => {
            store.setDialogShelf({ height });
          }}
        />
        <NumericInputField
          required
          value={store.dialogShelf?.positions}
          label="shelvNumberPosition"
          onChange={(positions) => {
            store.setDialogShelf({ positions });
          }}
        />
      </Box>
      {editLevel && (
        <Box className={"w-6/12"}>
          <NumericInputField
            value={store.dialogShelf?.level}
            label="shelvPlacementLevel"
            onChange={(level) => {
              store.setDialogShelf({ level });
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default RackShelfDialogForm;
