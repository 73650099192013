import { Box } from "@mui/material";
import { useState } from "react";
import Button from "wes_shell_app/button";
import { Dialog } from "wes_shell_app/dialog";
import RackShelfDialogForm from "./rack-shelf-dialog-form";
import { useShelvesStore } from "features/rack/hooks/use-shelves-store";

const RackShelvNew = () => {
  const store = useShelvesStore();
  const [isOverrideDialogVisible, setOverrideDialogVisibility] =
    useState(false);

  const handleShelvConfirm = async () => {
    store.addNewShelf();
    store.resetDialogShelf();
    setOverrideDialogVisibility(false);
  };

  const handleShelvCancel = () => {
    store.resetDialogShelf();
    setOverrideDialogVisibility(false);
  };
  return (
    <Box>
      <Dialog
        open={isOverrideDialogVisible}
        cancelAction={handleShelvCancel}
        cancelLabel="cancel"
        confirmAction={handleShelvConfirm}
        confirmLabel="save"
        confirmActionVariant="confirm"
        intlTitle={"shelvNew"}
      >
        <RackShelfDialogForm editLevel={true} />
      </Dialog>
      <Button
        variant={"contained"}
        intlId={"shelvNew"}
        onClick={() => setOverrideDialogVisibility(true)}
      />
    </Box>
  );
};

export default RackShelvNew;
