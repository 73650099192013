/**
 * Models of all data comming from server via API.
 * Type declarations according to Swagger.
 */

import { IWithId } from "wes_shell_app/api-types";
import { Severity } from "wes_shell_app/types";

export type IWebSocketHeaderInfoModel = {
  id: number;
  sortedItems: number;
  totalItems: number;
  sortedOrders: number;
  totalOrders: number;
  bufferItems: number;
  hospitalItems: number;
  duration: number;
  name: string;
  started: Date;
  state: string;
};

export enum IAPIPrinterType {
  NETWORK = "network",
  PORTABLEBT = "portableBT",
}

export type IAPIPrinterModelsProtocols = {
  model: string[];
  protocol: string[];
};

export type IAPIPrinterModel = {
  id: number;
  printerCode: string;
  ipAddress?: string;
  type: IAPIPrinterType;
  name: string;
  description?: string;
  stationId: number;
  protocol: string;
  created: Date;
  updated: Date;
  model: string;
  btName: string | null;
};

export type IRackModel = {
  active?: boolean;
  name: string;
  stationId?: number;
  description: string;
  length: number;
  height?: number;
  depth: number;
  type: string;
  mobility: boolean;
  zeroGround: boolean;
  numberingRack: boolean;
  rackStartsWith: string;
  numberingShelf: boolean;
  shelfStartsWith: string;
  shelves: IServerCreateRackShelfModel[];
}

export type IServerCreateRackModel = IWithId & IRackModel;

export type IServerCreateRackShelfModel = IWithId & {
  shelfId: number;
  name: string;
  rackId: number;
  level: number;
  positions: number;
  height: number;
};

export type IServerRackModel = IWithId & IRackModel;
