import BaseTableViewStore from "wes_shell_app/base-table-view-store";
import { RackTableProvider } from "./data/rack-table-provider";
import { IRackFilterParams } from "../utils/rack-utils";
import { IRackModel, IServerRackModel } from "models/server-models";
import { IClientRackModel } from "models/client-models";
import { serverToClientRackModel } from "models/mappers";
import SharedContextStore from "wes_shell_app/shared-context-store";
import { action, makeObservable, observable } from "mobx";
import alerts from "wes_shell_app/alerts";
import { deleteItem, updateItem } from "wes_shell_app/api-utils";
import { environment } from "environment";
import { dataPOST } from "wes_shell_app/api-utils";
import { RackShelvesNameStore } from "./rack-shelves-name-store";

export type IAPIResponseCreaterRackModel = {
    content: any[];
    message: string;
}

export class RackViewStore extends BaseTableViewStore<
    IServerRackModel,
    IClientRackModel,
    IRackFilterParams,
    RackTableProvider
> {
    constructor() {
        super(new RackTableProvider());
        makeObservable(this, {
            showedRackCloneButton: observable,
            selectedRackNumberingUuid: observable,
            selectedRackNumbering: observable,
            selectedRackShelfNumberingUuid: observable,
            selectedRackShelfNumbering: observable,
            selectedCloneRackPlan: observable,
            rackTableRowSelected: observable,
            showRequiredMessage: observable,
            newCloneRackName: observable,
            selectedEditRack: observable,
            setRackNumberingUuid: action,
            setRackNumbering: action,
            setRackShelfNumberingUuid: action,
            setRackShelfNumbering: action,
            resetRackUuid: action,
            setRackPlanCloneButton: action,
            resetSelectedCloneRack: action,
            setSelectedCloneRack: action,
            setNewCloneRackName: action,
            setRackTableRowSelected: action,
            resetRackTableRowSelected: action,
            setShowRequiredMessage: action,
        })
    }

    private readonly appContext = new SharedContextStore();
    private readonly rackShelveNameStore = new RackShelvesNameStore();

    showedRackCloneButton: boolean = false;

    selectedRackNumberingUuid: boolean = false;
    selectedRackNumbering: boolean = false;
    selectedRackShelfNumberingUuid: boolean = false;
    selectedRackShelfNumbering: boolean = false;
    selectedCloneRackPlan: IClientRackModel | null = null;
    newCloneRackName: string = "";
    rackTableRowSelected: number | null = null;
    showRequiredMessage: boolean = false;
    selectDeleteRack: IClientRackModel | null = null;
    currentStationId: number = this.appContext.appContext.currentStationId;
    selectedEditRack: IClientRackModel | null = null;

    private _renameShelves = () => {
        this.selectedEditRack.shelves = this.selectedEditRack.shelves.sort((a, b) => a.level - b.level);
        this.selectedEditRack.shelves.forEach((shelf, index) => {
            shelf.name = index === 0 ? this.selectedEditRack.rackStartsWith : this.rackShelveNameStore.getNextPosition(this.selectedEditRack.shelves[index - 1].name);
        });
    }

    setNewCloneRackName = (name: string) => {
        this.newCloneRackName = name;
    }

    resetNewCloneRackName = () => {
        this.newCloneRackName = "";
    }

    setRackNumberingUuid = (rackNumbering: boolean) => {
        this.selectedRackNumberingUuid = rackNumbering;
    }

    setRackNumbering = (rackNumbering: boolean) => {
        this.selectedRackNumbering = rackNumbering;
    }

    setRackShelfNumberingUuid = (rackShelfNumbering: boolean) => {
        this.selectedRackShelfNumberingUuid = rackShelfNumbering;
    }

    setRackShelfNumbering = (rackShelfNumbering: boolean) => {
        this.selectedRackShelfNumbering = rackShelfNumbering;
    }

    resetRackUuid = () => {
        this.selectedRackNumberingUuid = false;
        this.selectedRackShelfNumberingUuid = false;
        this.selectedRackNumbering = false;
        this.selectedRackShelfNumbering = false;
    }

    setRackTableRowSelected = (id: number) => {
        this.rackTableRowSelected = id;
    }

    resetRackTableRowSelected = () => {
        this.rackTableRowSelected = null;
    }

    setRackPlanCloneButton(value: boolean) {
        this.showedRackCloneButton = value;
    }

    resetSelectedCloneRack() {
        this.selectedCloneRackPlan = null;
    }

    setSelectedCloneRack(id: number) {
        this.selectedCloneRackPlan = this.items.find((item) => item.id === id) || null;
    }

    setSelectEditRack(rack: IClientRackModel) {
        this.selectedEditRack = rack;
    }

    editSelectedEditRack = async (rack: IClientRackModel) => {
        this.selectedEditRack = rack;
    }

    resetSelectEditRack() {
        this.selectedEditRack = null;
    }

    setSelectDeleteRack(rack: IClientRackModel) {
        this.selectDeleteRack = rack;
    }

    async resetSelectDeleteRack() {
        await new Promise(resolve => setTimeout(resolve, 500));
        this.selectDeleteRack = null;
    }

    async editRackAPI() {
        try {
            this._renameShelves();
            await updateItem(`${environment.voiceServiceApi}ui/rack/${this.selectedEditRack?.id}`, {
                name: this.selectedEditRack?.name,
                description: this.selectedEditRack?.description,
                length: this.selectedEditRack?.length,
                depth: this.selectedEditRack?.depth,
                type: this.selectedEditRack?.type,
                mobility: this.selectedEditRack?.mobility,
                zeroGround: this.selectedEditRack?.zeroGround,
                numberingRack: this.selectedEditRack?.numberingRack,
                rackStartsWith: this.selectedEditRack?.rackStartsWith,
                numberingShelf: this.selectedEditRack?.numberingShelf,
                shelfStartsWith: this.selectedEditRack?.shelfStartsWith,
                shelves: this.selectedEditRack?.shelves,
            });
            this.resetSelectEditRack();
            this.forceReload();
        } catch (e) {
            const fixedErrorMessage = (e.message as string).replace('Error: ', '');
            const errorModel = JSON.parse(fixedErrorMessage);
            alerts.error(errorModel.message);
        }
    }

    async cloneRackAPI() {
        try {
            await dataPOST<IRackModel, IAPIResponseCreaterRackModel>(`${environment.voiceServiceApi}ui/rack/${this.currentStationId}`, {
                name: this.newCloneRackName,
                stationId: this.appContext.appContext.currentStationId,
                description: this.selectedCloneRackPlan?.description,
                length: this.selectedCloneRackPlan?.length,
                depth: this.selectedCloneRackPlan?.depth,
                type: this.selectedCloneRackPlan?.type,
                mobility: this.selectedCloneRackPlan?.mobility,
                zeroGround: this.selectedCloneRackPlan?.zeroGround,
                numberingRack: this.selectedCloneRackPlan?.numberingRack,
                rackStartsWith: this.selectedCloneRackPlan?.rackStartsWith,
                numberingShelf: this.selectedCloneRackPlan?.numberingShelf,
                shelfStartsWith: this.selectedCloneRackPlan?.shelfStartsWith,
                shelves: this.selectedCloneRackPlan?.shelves,
            });
            this.resetSelectedCloneRack();
            this.forceReload();
        } catch (e) {
            const fixedErrorMessage = (e.message as string).replace('Error: ', '');
            const errorModel = JSON.parse(fixedErrorMessage);
            alerts.error(errorModel.message);
        }
    }

    async deleteRackAPI() {
        try {
            await deleteItem(`${environment.voiceServiceApi}ui/rack`, this.selectDeleteRack?.id);
            this.resetSelectDeleteRack();
            this.resetRackTableRowSelected();
            this.resetNewCloneRackName();
            this.setRackPlanCloneButton(false);
            this.forceReload();
        } catch (e) {
            const fixedErrorMessage = (e.message as string).replace('Error: ', '');
            const errorModel = JSON.parse(fixedErrorMessage);
            alerts.error(errorModel.message);
        }
    }

    setShowRequiredMessage = (value: boolean) => this.showRequiredMessage = value;

    mapServerToClientModel = (item): IClientRackModel =>
        serverToClientRackModel(item);

    get currentUserStore() { return this.appContext.appContext.accessRights; }
    get isAdmin() { return this.currentUserStore.isAdminRole || false; }
    get isLeader() { return this.currentUserStore.isLeaderRole || false; }
    get isUser() { return this.currentUserStore.isUserRole || false; }

    get rightRemoveRack() { return this.isAdmin || this.isLeader; }
    get rightEditRack() { return this.isAdmin || this.isLeader; }

    get isLoaded() { return this.currentUserStore.isLoaded && this.tableProvider.isLoaded; }
}