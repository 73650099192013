const basePath = "/voice-picking";
export enum VoiceRoutePaths {
  home = "",
  printers = "/printers",
  parameters = "/parameters",
  racks = "/racks",
}

export function voiceMenu() {
  return {
    items: [
      {
        title: "dashboard",
        link: basePath + VoiceRoutePaths.home,
        icon: "Dashboard",
      },
      {
        title: "printers",
        link: basePath + VoiceRoutePaths.printers,
        icon: "Print",
      },
      {
        title: "parameters",
        link: basePath + VoiceRoutePaths.parameters,
        icon: "EditNote",
      },
      {
        title: "racks",
        link: basePath + VoiceRoutePaths.racks,
        icon: "CalendarViewMonth",
      },
    ],
  };
}
