import { Box } from "@mui/material";
import { useRackStore } from "features/rack/hooks/use-rack-store";
import {
  MobilityItems,
  NumericRackingItems,
  NumericShelfItems,
  ShelfTypeItems,
  ZeroGroundItems,
} from "features/rack/utils/rack-utils";
import { observer } from "mobx-react";
import { IServerCreateRackModel } from "models/server-models";
import { useEffect } from "react";
import { getTranslation } from "wes_shell_app/localization-utils";
import NumericInputField from "wes_shell_app/numeric-input-field";
import Select from "wes_shell_app/select";
import TextInputField from "wes_shell_app/text-input-field";

const RackEditDialogForm = observer(() => {
  const store = useRackStore();

  const patchItem = (item: Partial<IServerCreateRackModel>) => {
    store.editSelectedEditRack({
      ...store.selectedEditRack,
      ...item,
    });
  };

  const rackStartHandler = (
    rackStartsWith: string,
    item: Partial<IServerCreateRackModel>
  ) => {
    const regex = store.selectedRackNumbering ? /^[0-9]*$/ : /^[A-Za-z]*$/;
    if (!regex.test(rackStartsWith)) {
      patchItem({ rackStartsWith: item.rackStartsWith });
      return;
    }
    patchItem({ rackStartsWith });
  };

  const shelfStartHandler = (
    shelfStartsWith: string,
    item: Partial<IServerCreateRackModel>
  ) => {
    const regex = store.selectedRackShelfNumbering ? /^[0-9]*$/ : /^[A-Za-z]*$/;
    if (!regex.test(shelfStartsWith)) {
      patchItem({ shelfStartsWith: item.shelfStartsWith });
      return;
    }
    patchItem({ shelfStartsWith });
  };

  useEffect(() => {
    if (store.selectedEditRack) {
      store.setRackNumbering(store.selectedEditRack.numberingRack);
      store.setRackShelfNumbering(store.selectedEditRack.numberingShelf);
    }
  }, [store, store.selectedEditRack]);

  return (
    <Box className="flex flex-col justify-between space-y-4">
      <TextInputField
        required
        value={store.selectedEditRack?.name}
        label="name"
        onChange={(name) => patchItem({ name })}
      />
      <TextInputField
        value={store.selectedEditRack?.description}
        label="description"
        onChange={(description) => patchItem({ description })}
      />
      <Box className="flex flex-row gap-2">
        <NumericInputField
          required
          value={store.selectedEditRack?.length}
          label={`${getTranslation("rackLength")} (mm)`}
          onChange={(length) => patchItem({ length })}
        />
        <NumericInputField
          required
          value={store.selectedEditRack?.depth}
          label={`${getTranslation("rackDepth")} (mm)`}
          onChange={(depth) => patchItem({ depth })}
        />
        <Select
          required
          fullWidth
          label={getTranslation("type")}
          value={store.selectedEditRack?.type}
          items={ShelfTypeItems}
          onChange={(type) => patchItem({ type: String(type) })}
        />
      </Box>
      <Box className="flex flex-row gap-2">
        <Select
          required
          fullWidth
          label={getTranslation("rackTypeMobile")}
          value={
            String(store.selectedEditRack?.mobility) === "undefined"
              ? undefined
              : String(store.selectedEditRack?.mobility)
          }
          items={MobilityItems}
          onChange={(mobility) =>
            patchItem({ mobility: mobility === "true" ? true : false })
          }
        />
        <Select
          required
          fullWidth
          label={getTranslation("rackStorageUnder")}
          value={
            String(store.selectedEditRack?.zeroGround) === "undefined"
              ? undefined
              : String(store.selectedEditRack?.zeroGround)
          }
          items={ZeroGroundItems}
          onChange={(zeroGround) =>
            patchItem({
              zeroGround: zeroGround === "true" ? true : false,
            })
          }
        />
      </Box>
      <Box className="flex flex-row gap-2">
        <Select
          required
          fullWidth
          label={getTranslation("rackNumerickMarking")}
          value={
            String(store.selectedEditRack?.numberingRack) === "undefined"
              ? undefined
              : String(store.selectedEditRack?.numberingRack)
          }
          items={NumericRackingItems}
          onChange={(numberingRack) => {
            patchItem({
              numberingRack: numberingRack === "true" ? true : false,
            });
            patchItem({ rackStartsWith: "" });
            store.setRackNumbering(numberingRack === "true");
          }}
        />
        <TextInputField
          required
          value={store.selectedEditRack?.rackStartsWith}
          label="rackMarkingShelvesFrom"
          onChange={(rackStartsWith) =>
            rackStartHandler(rackStartsWith, store.selectedEditRack)
          }
        />
      </Box>
      <Box className="flex flex-row gap-2">
        <Select
          required
          fullWidth
          label={getTranslation("rackNumerickPlaces")}
          value={
            String(store.selectedEditRack?.numberingShelf) === "undefined"
              ? undefined
              : String(store.selectedEditRack?.numberingShelf)
          }
          items={NumericShelfItems}
          onChange={(numberingShelf) => {
            patchItem({
              numberingShelf: numberingShelf === "true" ? true : false,
            });
            patchItem({ shelfStartsWith: "" });
            store.setRackShelfNumbering(numberingShelf === "true");
          }}
        />
        <TextInputField
          required
          value={store.selectedEditRack?.shelfStartsWith}
          label="rackMarkingPlacesFrom"
          onChange={(shelfStartsWith) =>
            shelfStartHandler(shelfStartsWith, store.selectedEditRack)
          }
        />
      </Box>
    </Box>
  );
});

export default RackEditDialogForm;
