import BaseView from "wes_shell_app/base-view";
import RackDataTableFilter from "../components/rack-data-table-filter";
import RackModelDialogForm from "../components/dialogs/rack/rack-model-dialog-form";
import RackDataTable from "../components/rack-data-table";
import RackCloneDialog from "../components/dialogs/rack/rack-clone-dialog";
import { useEffect } from "react";
import { useRackStore } from "../hooks/use-rack-store";
import RackShelvesDrawer from "../components/rack-shelves-drawer";

const RackView = () => {
  const store = useRackStore();

  useEffect(() => {
    return () => {
      store.setRackPlanCloneButton(false);
      store.resetSelectedCloneRack();
    };
  });

  return (
    <BaseView>
      <RackDataTableFilter
        cloneActionContent={<RackCloneDialog />}
        actionContent={<RackModelDialogForm dialogTitleIntl={"rackNew"} />}
      />
      <RackDataTable />
      <RackShelvesDrawer />
    </BaseView>
  );
};

export default RackView;
