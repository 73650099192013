import { observer } from "mobx-react";
import { useShelvesStore } from "../hooks/use-shelves-store";
import { Box } from "@mui/material";
import RackIamge from "./rack-image";
import { CalendarViewMonth } from "@mui/icons-material";

const RackVisualization = observer(() => {
  const store = useShelvesStore();

  return (
    <Box
      display="flex"
      minHeight={"50%"}
      width={"40%"}
      alignItems={"end"}
      justifyContent={"center"}
      height={"fit-content"}
      padding={"1rem 0"}
    >
      {store.shelves.length > 0 ? (
        <RackIamge />
      ) : (
        <Box>
          <CalendarViewMonth
            sx={{
              fontSize: 220,
              color: "#eeeeee",
            }}
          />
        </Box>
      )}
    </Box>
  );
});

export default RackVisualization;
