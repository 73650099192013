import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import { IClientRackModel } from "models/client-models";
import DataTable, { IDataTableHeaderCellProps } from "wes_shell_app/data-table";
import { useRackStore } from "../hooks/use-rack-store";
import { RackViewStore } from "../stores/rack-view-store";
import { getTranslation } from "wes_shell_app/localization-utils";
import Button from "wes_shell_app/button";
import { Delete, Edit, List } from "@mui/icons-material";
import RackDeleteDialog from "./dialogs/rack/rack-delete-dialog";
import { useState } from "react";
import RackEditDialog from "./dialogs/rack/rack-edit-dialog";
import { useShelvesStore } from "../hooks/use-shelves-store";
import { RackShelvesStore } from "../stores/rack-shelves-store";

const columns: IDataTableHeaderCellProps[] = [
  {
    label: "id",
    sortKey: "rackId",
  },
  {
    label: "name",
    sortKey: "name",
  },
  {
    label: "state",
    sortKey: "active",
  },
  {
    label: "description",
    sortKey: "description",
  },
  {
    label: "rackHeight",
    sortKey: "height",
  },
  {
    label: "rackLength",
    sortKey: "length",
  },
  {
    label: "rackDepth",
    sortKey: "depth",
  },
  {
    label: "type",
    sortKey: "type",
  },
  {
    label: "rackTypeMobile",
    sortKey: "mobility",
  },
  {
    label: "rackStorageUnder",
    sortKey: "zeroGround",
  },
  {
    label: "rackMarkingShelvesFrom",
    sortKey: "rackStartsWith",
  },
  {
    label: "rackMarkingPlacesFrom",
    sortKey: "shelfStartsWith",
  },
];

const RackDataTable = observer(() => {
  const store = useRackStore();
  const shelvStore = useShelvesStore();
  const [openDeleteRackDialog, setOpenDeleteRackDialog] = useState(false);
  const [openEditRackDialog, setOpenEditRackDialog] = useState(false);

  const handleRowSelect = (id: number) => {
    if (store.rackTableRowSelected === id) {
      store.resetRackTableRowSelected();
      store.setRackPlanCloneButton(false);
      store.resetSelectedCloneRack();
    } else {
      store.setRackTableRowSelected(id);
      store.setRackPlanCloneButton(true);
      store.setSelectedCloneRack(id);
    }
  };

  return (
    <>
      <RackEditDialog
        openDialog={openEditRackDialog}
        setOpenDialog={setOpenEditRackDialog}
      />
      <RackDeleteDialog
        openDialog={openDeleteRackDialog}
        setOpenDialog={setOpenDeleteRackDialog}
      />
      <DataTable
        page={store.page}
        isLoading={!store.isLoaded}
        onPageChange={store.setPage}
        activeSortProperty={store.sortBy}
        onSortChange={store.setSort}
        onDirectionChange={store.setDirection}
        direction={store.direction}
        pageCount={store.totalCount}
        rows={store.items}
        pageSize={store.pageSize}
        onPageSizeChange={store.setPageSize}
        columns={columns}
        rowTemplate={(item) => (
          <RowTemplate
            key={item.id}
            item={item}
            store={store}
            shelvStore={shelvStore}
            isAdmin={store.isAdmin}
            onSelect={() => handleRowSelect(item.id)}
            setOpenDeleteRackDialog={setOpenDeleteRackDialog}
            setOpenEditRackDialog={setOpenEditRackDialog}
          />
        )}
      />
    </>
  );
});

type IRowTemplateProps = {
  item: IClientRackModel;
  store: RackViewStore;
  shelvStore: RackShelvesStore;
  isAdmin?: boolean;
  onSelect: () => void;
  setOpenDeleteRackDialog: (value: boolean) => void;
  setOpenEditRackDialog: (value: boolean) => void;
};

const RowTemplate = observer((props: IRowTemplateProps) => {
  const {
    item,
    store,
    onSelect,
    setOpenDeleteRackDialog,
    setOpenEditRackDialog,
    shelvStore,
  } = props;
  const {
    id,
    name,
    active,
    description,
    length,
    height,
    depth,
    type,
    mobility,
    zeroGround,
    rackStartsWith,
    shelfStartsWith,
  } = item;

  return (
    <TableRow
      onClick={onSelect}
      selected={store.rackTableRowSelected === id}
      sx={{
        cursor: "pointer",
        backgroundColor:
          store.rackTableRowSelected === id
            ? "rgb(37 58 127 / 13%) !important"
            : "",
      }}
    >
      <TableCell>{id}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>
        {getTranslation(active ? "statusActive" : "statusInactiv")}
      </TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>{height}</TableCell>
      <TableCell>{length}</TableCell>
      <TableCell>{depth}</TableCell>
      <TableCell>{type}</TableCell>
      <TableCell>{getTranslation(mobility ? "yes" : "no")}</TableCell>
      <TableCell>{getTranslation(zeroGround ? "yes" : "no")}</TableCell>
      <TableCell>{rackStartsWith}</TableCell>
      <TableCell>{shelfStartsWith}</TableCell>
      <TableCell>
        {store.rightEditRack && (
          <Button
            variant="icon"
            icon={<Edit color="primary" />}
            onClick={(event) => {
              event.stopPropagation();
              setOpenEditRackDialog(true);
              store.setSelectEditRack(item);
            }}
          />
        )}
      </TableCell>
      <TableCell>
        {store.rightRemoveRack && (
          <Button
            variant="icon"
            icon={<Delete color="error" />}
            onClick={(event) => {
              event.stopPropagation();
              setOpenDeleteRackDialog(true);
              store.setSelectDeleteRack(item);
            }}
          />
        )}
      </TableCell>
      <TableCell>
        <Button
          variant="icon"
          icon={<List color="primary" />}
          onClick={(event) => {
            event.stopPropagation();
            shelvStore.rackData = JSON.parse(JSON.stringify(item));
            shelvStore.openDrawer();
          }}
        />
      </TableCell>
    </TableRow>
  );
});

export default RackDataTable;
