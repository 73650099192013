import { Dialog } from "wes_shell_app/dialog";
import { useRackStore } from "features/rack/hooks/use-rack-store";
import { observer } from "mobx-react";
import { useState } from "react";
import Button from "wes_shell_app/button";
import RackCloneDialogForm from "./rack-clone-dialog-form";

const RackCloneDialog = observer(() => {
  const [isOverrideDialogVisible, setOverrideDialogVisibility] =
    useState(false);
  const store = useRackStore();

  const handleCloneRack = async () => {
    await store.cloneRackAPI();
    store.setShowRequiredMessage(false);
    store.forceReload();
    setOverrideDialogVisibility(false);
    store.resetSelectedCloneRack();
    store.setRackPlanCloneButton(false);
    store.resetRackTableRowSelected();
    store.setNewCloneRackName("");
  };

  const handleCloneRackCancel = () => {
    setOverrideDialogVisibility(false);
    store.setNewCloneRackName("");
    store.setShowRequiredMessage(false);
  };
  return (
    <>
      <Dialog
        open={isOverrideDialogVisible}
        cancelAction={handleCloneRackCancel}
        cancelLabel="cancel"
        confirmAction={handleCloneRack}
        confirmLabel="clone"
        confirmActionVariant="confirm"
        intlTitle={"rackClone"}
      >
        <RackCloneDialogForm />
      </Dialog>
      <Button
        variant={"contained"}
        intlId={"clone"}
        onClick={() => setOverrideDialogVisibility(true)}
      />
    </>
  );
});

export default RackCloneDialog;
