import Select from "wes_shell_app/select";
import { SelectItemType } from "wes_shell_app/types";
import { Close } from "@mui/icons-material";
import { isUndefined } from "lodash";
import Button from "wes_shell_app/button";
import { useVoicePrintersStore } from "../hooks/use-printers-store";
import { useEffect, useState } from "react";

type IPrinterTypeSelectorProps = {
  value: string;
  onChange: (v: string) => void;
  onClear?: () => void;
  error?: boolean;
  required?: boolean;
};

export const PrinterProtocolSelector = ({
  value,
  onChange,
  onClear,
  required,
}: IPrinterTypeSelectorProps) => {
  const [items, setItems] = useState<SelectItemType[] | null>(null);
  const store = useVoicePrintersStore();

  useEffect(() => {
    if (store.printerProtocols) {
      const transformedItems: SelectItemType[] = store.printerProtocols.map(
        (protocol) => ({
          content: protocol,
          id: protocol,
        })
      );
      setItems(transformedItems);
    }
  });

  return (
    <Select
      fullWidth
      intlId="protocol"
      placeholder="protocol"
      items={items}
      required={required}
      value={!isUndefined(value) ? `${value}` : undefined}
      onChange={onChange}
      endAdornment={
        !isUndefined(value) && onClear ? (
          <div className="mr-4 flex align-center">
            <Button variant="small-icon" onClick={onClear}>
              <Close fontSize="small" color="action" />
            </Button>
          </div>
        ) : undefined
      }
    />
  );
};
