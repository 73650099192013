import { Box } from "@mui/material";
import { useRackStore } from "features/rack/hooks/use-rack-store";
import { useState } from "react";
import { Dialog } from "wes_shell_app/dialog";
import { getTranslation } from "wes_shell_app/localization-utils";
import Text from "wes_shell_app/text";

type IRackDeleteDialogProps = {
  openDialog: boolean;
  setOpenDialog: (open: boolean) => void;
};

const RackDeleteDialog = (props: IRackDeleteDialogProps) => {
  const { openDialog, setOpenDialog } = props;
  const store = useRackStore();

  const confirmHandler = () => {
    setOpenDialog(false);
    store.deleteRackAPI();
  };

  const cancelHandler = () => {
    setOpenDialog(false);
    store.resetSelectDeleteRack();
  };

  return (
    <Dialog
      open={openDialog}
      intlTitle={"deleteRack"}
      cancelAction={cancelHandler}
      cancelLabel="cancel"
      confirmAction={confirmHandler}
      confirmLabel="delete"
      confirmActionVariant="confirm"
    >
      <Box>
        <Text
          intlId={"confirmDeleteRack"}
          intlParams={{ name: store.selectDeleteRack?.name }}
        />
      </Box>
    </Dialog>
  );
};

export default RackDeleteDialog;
