import React from "react";
import { AppProvider } from "./providers/app-provider";
import { VoiceRoutes } from "routing/voice-routes";
import { setVoiceTranslation } from "localization/voice-localization";
import { Alerts } from "wes_shell_app/alerts";

function App() {
  React.useMemo(() => {
    setVoiceTranslation();
  }, []);

  return (
    <AppProvider>
      <Alerts />
      <VoiceRoutes />
    </AppProvider>
  );
}

export default App;
