import { runInAction } from "mobx";
import { VoiceDashboardViewStore } from "features/dashboard/stores/voice-dashboard-view-store";
import { VoicePrintersViewStore } from "features/printers/stores/view/voice-printers-view-store";
import { ParametersViewStore } from "wes_shell_app/parameters-view-store";
import { VoiceParametersDataTableProvider } from "features/parameters/stores/voice-parameters-data-table-provider";
import { RackViewStore } from "features/rack/stores/rack-view-store";
import { RackShelvesStore } from "features/rack/stores/rack-shelves-store";

export class ViewStore {
  readonly dashboard = new VoiceDashboardViewStore();
  readonly printers = new VoicePrintersViewStore();
  readonly parameters = new ParametersViewStore(
    new VoiceParametersDataTableProvider()
  );
  readonly racks = new RackViewStore();
}

export class CommonStore {
  readonly shelvesStore = new RackShelvesStore();
}

export class VoiceRootStore {
  readonly view = new ViewStore();
  readonly common = new CommonStore();
}

export let voiceRootStore = new VoiceRootStore();

export function initStores() {
  voiceRootStore = runInAction(() => new VoiceRootStore());
}

export default voiceRootStore;
