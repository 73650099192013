import React from "react";
import TextInputField from "wes_shell_app/text-input-field";
import Text from "wes_shell_app/text";
import { useRackStore } from "features/rack/hooks/use-rack-store";
import { observer } from "mobx-react";

const RackCloneDialogForm = observer(() => {
  const store = useRackStore();

  const handlChangeInputField = (v: string) => {
    store.setNewCloneRackName(v);
  };

  return (
    <div className="flex flex-col justify-between space-y-4">
      <Text
        intlId="rackCreateNewCloneRack"
        intlParams={{ name: store.selectedCloneRackPlan?.name }}
      />
      <TextInputField
        required
        value={store.newCloneRackName}
        label="name"
        onChange={(v) => {
          handlChangeInputField(v);
        }}
      />
    </div>
  );
});

export default RackCloneDialogForm;
