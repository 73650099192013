import { observer } from "mobx-react";
import BaseView from "wes_shell_app/base-view";

const DashboardView = observer(() => {
  return (
    <BaseView cardBackgroundColor={false}>
      HERE WILL BE VOICE PICKING DASHBOARD. SITE UNDER CONSTRUCTION!
    </BaseView>
  );
});

export default DashboardView;
